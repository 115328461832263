import React, { useState, useEffect } from 'react';
import { 
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell
} from 'recharts';

// TypeScript interface for interview metrics data
interface InterviewMetric {
  name: string;
  vertical: string;
  position: string;
  group: string;
  subgroup: string;
  current_use: number;
  attitude: number;
  impact: number;
  raw_summary: string;
  raw_current_use: string;
  raw_opportunities: string;
  raw_attitude: string;
  raw_impact: string;
}

// Filter type for dashboard state
interface DashboardFilters {
  vertical: string;
  group: string;
  subgroup: string;
  searchTerm: string;
}

// Main Dashboard component
const Dashboard: React.FC = () => {
  const [metrics, setMetrics] = useState<InterviewMetric[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<DashboardFilters>({
    vertical: '',
    group: '',
    subgroup: '',
    searchTerm: ''
  });
  const [selectedPerson, setSelectedPerson] = useState<InterviewMetric | null>(null);
  const [interviewContent, setInterviewContent] = useState<string>('');
  const [showFullInterview, setShowFullInterview] = useState<boolean>(false);
  const [loadingInterview, setLoadingInterview] = useState<boolean>(false);
  
  // Sorting state
  const [sortConfig, setSortConfig] = useState<{
    key: keyof InterviewMetric | null;
    direction: 'ascending' | 'descending';
  }>({
    key: null, 
    direction: 'ascending'
  });
  
  // Derived state for filter options
  const [verticals, setVerticals] = useState<string[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [subgroups, setSubgroups] = useState<string[]>([]);

  // Fetch metrics data on component mount
  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        const response = await fetch('/api/metrics');
        if (!response.ok) {
          throw new Error(`Failed to fetch metrics: ${response.status}`);
        }
        const data = await response.json();
        setMetrics(data.metrics);
        
        // Extract unique filter values using Array.from instead of spread
        const uniqueVerticals: string[] = Array.from(new Set(data.metrics.map((m: InterviewMetric) => m.vertical)));
        const uniqueGroups: string[] = Array.from(new Set(data.metrics.map((m: InterviewMetric) => m.group)));
        const uniqueSubgroups: string[] = Array.from(new Set(data.metrics.map((m: InterviewMetric) => m.subgroup)));
        
        setVerticals(uniqueVerticals);
        setGroups(uniqueGroups.filter(Boolean)); // Filter out empty strings
        setSubgroups(uniqueSubgroups.filter(Boolean));
        
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch metrics');
        setLoading(false);
      }
    };
    
    fetchMetrics();
  }, []);
  
  // Apply filters to metrics data
  const filteredMetrics = metrics.filter(metric => {
    return (
      (filters.vertical === '' || metric.vertical === filters.vertical) &&
      (filters.group === '' || metric.group === filters.group) &&
      (filters.subgroup === '' || metric.subgroup === filters.subgroup) &&
      (filters.searchTerm === '' || 
        metric.name.toLowerCase().includes(filters.searchTerm.toLowerCase()) ||
        metric.position.toLowerCase().includes(filters.searchTerm.toLowerCase()))
    );
  });
  
  // Calculate average metrics for filtered data
  const averages = filteredMetrics.length > 0 
    ? {
        current_use: parseFloat((filteredMetrics.reduce((sum, m) => sum + m.current_use, 0) / filteredMetrics.length).toFixed(1)),
        attitude: parseFloat((filteredMetrics.reduce((sum, m) => sum + m.attitude, 0) / filteredMetrics.length).toFixed(1)),
        impact: parseFloat((filteredMetrics.reduce((sum, m) => sum + m.impact, 0) / filteredMetrics.length).toFixed(1))
      }
    : { current_use: 0, attitude: 0, impact: 0 };
  
  // Prepare data for charts
  const verticalData = verticals.map(vertical => {
    const verticalMetrics = metrics.filter(m => m.vertical === vertical);
    return {
      name: vertical,
      current_use: parseFloat((verticalMetrics.reduce((sum, m) => sum + m.current_use, 0) / verticalMetrics.length).toFixed(1)),
      attitude: parseFloat((verticalMetrics.reduce((sum, m) => sum + m.attitude, 0) / verticalMetrics.length).toFixed(1)),
      impact: parseFloat((verticalMetrics.reduce((sum, m) => sum + m.impact, 0) / verticalMetrics.length).toFixed(1)),
      count: verticalMetrics.length
    };
  });
  
  // Distribution data for pie charts
  const currentUseDistribution = [0, 1, 2, 3, 4].map(level => ({
    name: `Level ${level}`,
    value: filteredMetrics.filter(m => m.current_use === level).length
  }));
  
  const attitudeDistribution = [0, 1, 2, 3, 4].map(level => ({
    name: `Level ${level}`,
    value: filteredMetrics.filter(m => m.attitude === level).length
  }));
  
  // Handle filter changes
  const handleFilterChange = (filterName: keyof DashboardFilters, value: string) => {
    setFilters(prev => ({ ...prev, [filterName]: value }));
    // Reset selected person when changing filters
    setSelectedPerson(null);
  };
  
  // Handle person selection
  const handlePersonSelect = async (person: InterviewMetric) => {
    setSelectedPerson(person);
    setLoadingInterview(true);
    setInterviewContent('');
    
    try {
      console.log(`Fetching interview for ${person.name} in vertical ${person.vertical}`);
      const response = await fetch(`/api/interview/${encodeURIComponent(person.vertical)}/${encodeURIComponent(person.name)}`);
      console.log(`Response status: ${response.status}`);
      
      if (response.ok) {
        const data = await response.json();
        setInterviewContent(data.content);
        console.log('Interview content loaded successfully');
      } else {
        // If we can't get the full interview, use the raw data from CSV as fallback
        console.warn(`Failed to fetch interview content: ${response.status}. Using fallback data.`);
        const fallbackContent = `
<summary>${person.raw_summary}</summary>
<current_use>${person.raw_current_use}</current_use>
<opportunities>${person.raw_opportunities}</opportunities>
<attitude>${person.raw_attitude}</attitude>
<impact>${person.raw_impact}</impact>
<!-- Original Transcript -->
No transcript available
`;
        setInterviewContent(fallbackContent);
      }
    } catch (err) {
      console.error('Error fetching interview content:', err);
      
      // Create a fallback content from the CSV row data
      const fallbackContent = `
<summary>${person.raw_summary}</summary>
<current_use>${person.raw_current_use}</current_use>
<opportunities>${person.raw_opportunities}</opportunities>
<attitude>${person.raw_attitude}</attitude>
<impact>${person.raw_impact}</impact>
<!-- Original Transcript -->
No transcript available due to error: ${err instanceof Error ? err.message : 'Unknown error'}
`;
      setInterviewContent(fallbackContent);
    } finally {
      setLoadingInterview(false);
    }
  };
  
  // Parse and format interview content with memoization
  const parseInterviewContent = (content: string) => {
    // Check for null or empty content
    if (!content || content.trim() === '') {
      console.warn('Empty content passed to parseInterviewContent');
      return {
        summary: '',
        currentUse: '',
        opportunities: '',
        attitude: '',
        impact: ''
      };
    }
    
    console.log('Parsing interview content');
    
    // Extract XML sections using a different approach without 's' flag
    const extractSection = (tagName: string, content: string) => {
      const startTag = `<${tagName}>`;
      const endTag = `</${tagName}>`;
      
      const startIndex = content.indexOf(startTag);
      if (startIndex === -1) {
        console.warn(`Tag <${tagName}> not found in content`);
        return '';
      }
      
      const endIndex = content.indexOf(endTag, startIndex + startTag.length);
      if (endIndex === -1) {
        console.warn(`Closing tag </${tagName}> not found in content`);
        return '';
      }
      
      return content.substring(startIndex + startTag.length, endIndex).trim();
    };
    
    const summary = extractSection('summary', content);
    const currentUse = extractSection('current_use', content);
    const opportunities = extractSection('opportunities', content);
    const attitude = extractSection('attitude', content);
    const impact = extractSection('impact', content);
    
    return { summary, currentUse, opportunities, attitude, impact };
  };
  
  // Format conversation with Me/Them indicators
  const formatConversation = (content: string) => {
    // Check for null or empty content
    if (!content || content.trim() === '') {
      console.warn('Empty content passed to formatConversation');
      return <div className="text-gray-500">No transcript content available</div>;
    }

    // Split at the original transcript marker
    const originalTranscriptMarker = '<!-- Original Transcript -->';
    
    if (!content.includes(originalTranscriptMarker)) {
      console.warn('Original transcript marker not found in content');
      
      // Try to detect conversation format directly
      if (content.includes('Me:') || content.includes('Them:')) {
        console.log('Detected conversation format without marker');
        return formatLines(content);
      }
      
      return <div className="text-gray-500">Transcript format not recognized</div>;
    }
    
    const fullTranscript = content.split(originalTranscriptMarker)[1].trim();
    return formatLines(fullTranscript);
  };

  // Helper function to format conversation lines
  const formatLines = (text: string) => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    
    if (lines.length === 0) {
      return <div className="text-gray-500">No conversation content found</div>;
    }
    
    const formattedLines = lines.map((line, index) => {
      if (line.startsWith('Me:')) {
        return (
          <div key={index} className="py-2">
            <span className="font-bold text-blue-600">Me:</span>{' '}
            {line.substring(3).trim()}
          </div>
        );
      } else if (line.startsWith('Them:')) {
        return (
          <div key={index} className="py-2">
            <span className="font-bold text-green-600">Them:</span>{' '}
            {line.substring(5).trim()}
          </div>
        );
      } else {
        return <div key={index} className="py-1">{line}</div>;
      }
    });
    
    return <div className="space-y-1">{formattedLines}</div>;
  };
  
  // Colors for charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];
  
  // Request sort handler
  const requestSort = (key: keyof InterviewMetric) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    
    // If we're already sorting by this key, toggle direction
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    
    setSortConfig({ key, direction });
  };
  
  // Get sorted items based on current sort configuration
  const getSortedItems = (items: InterviewMetric[]) => {
    if (!sortConfig.key) return items;
    
    return [...items].sort((a, b) => {
      if (a[sortConfig.key!] < b[sortConfig.key!]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key!] > b[sortConfig.key!]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };
  
  // Get sort direction indicator for column header
  const getSortDirectionIndicator = (key: keyof InterviewMetric) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'ascending' ? ' ↑' : ' ↓';
  };
  
  if (loading) return <div className="flex justify-center items-center h-full">Loading metrics data...</div>;
  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;
  
  return (
    <div className="p-4">
      {selectedPerson ? (
        /* Individual Detail View - Show only this when a person is selected */
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <button 
                onClick={() => setSelectedPerson(null)}
                className="mr-4 text-gray-500 hover:text-gray-700 bg-gray-100 p-2 rounded-md"
                aria-label="Back to dashboard"
              >
                &times;
              </button>
              <h2 className="text-lg font-semibold">Interview Details: {selectedPerson.name}</h2>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
            <div className="p-3 bg-gray-50 rounded-md">
              <h3 className="font-medium">Position</h3>
              <p>{selectedPerson.position}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-md">
              <h3 className="font-medium">Group</h3>
              <p>{selectedPerson.group || 'N/A'}</p>
            </div>
            <div className="p-3 bg-gray-50 rounded-md">
              <h3 className="font-medium">Subgroup</h3>
              <p>{selectedPerson.subgroup || 'N/A'}</p>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="p-3 bg-blue-50 rounded-md">
              <h3 className="font-medium text-blue-700">Current Use Score</h3>
              <p className="text-2xl font-bold">{selectedPerson.current_use} / 4</p>
            </div>
            <div className="p-3 bg-green-50 rounded-md">
              <h3 className="font-medium text-green-700">Attitude Score</h3>
              <p className="text-2xl font-bold">{selectedPerson.attitude} / 4</p>
            </div>
            <div className="p-3 bg-yellow-50 rounded-md">
              <h3 className="font-medium text-yellow-700">Impact Potential</h3>
              <p className="text-2xl font-bold">{selectedPerson.impact}%</p>
            </div>
          </div>
          
          {loadingInterview ? (
            <div className="p-6 text-center">
              <p>Loading interview content...</p>
            </div>
          ) : (
            <>
              {/* Parse content once to avoid redundant parsing */}
              {(() => {
                const { 
                  summary, 
                  currentUse, 
                  opportunities, 
                  attitude, 
                  impact 
                } = parseInterviewContent(interviewContent);
                
                return (
                  <div className="space-y-4">
                    {summary && (
                      <div>
                        <h3 className="font-medium text-lg mb-2">Summary</h3>
                        <div className="bg-gray-50 p-3 rounded-md">
                          <p className="whitespace-pre-line">{summary}</p>
                        </div>
                      </div>
                    )}
                    
                    {currentUse && (
                      <div>
                        <h3 className="font-medium text-lg mb-2">Current Use</h3>
                        <div className="bg-gray-50 p-3 rounded-md">
                          <p className="whitespace-pre-line">{currentUse}</p>
                        </div>
                      </div>
                    )}
                    
                    {opportunities && (
                      <div>
                        <h3 className="font-medium text-lg mb-2">Opportunities</h3>
                        <div className="bg-gray-50 p-3 rounded-md">
                          <p className="whitespace-pre-line">{opportunities}</p>
                        </div>
                      </div>
                    )}
                    
                    {attitude && (
                      <div>
                        <h3 className="font-medium text-lg mb-2">Attitude</h3>
                        <div className="bg-gray-50 p-3 rounded-md">
                          <p className="whitespace-pre-line">{attitude}</p>
                        </div>
                      </div>
                    )}
                    
                    {impact && (
                      <div>
                        <h3 className="font-medium text-lg mb-2">Impact</h3>
                        <div className="bg-gray-50 p-3 rounded-md">
                          <p className="whitespace-pre-line">{impact}</p>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })()}
              
              <div className="mt-8 border-t pt-4">
                <button
                  onClick={() => setShowFullInterview(!showFullInterview)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {showFullInterview ? 'Hide Full Interview' : 'Show Full Interview'}
                </button>
                
                {showFullInterview && (
                  <div className="mt-4 border p-4 rounded-md bg-gray-50 max-h-96 overflow-y-auto">
                    <h3 className="text-lg font-medium mb-4">Full Interview Transcript</h3>
                    {formatConversation(interviewContent)}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        /* Main Dashboard View - Only show when no person is selected */
        <>
          <h1 className="text-2xl font-bold mb-6">Interview Analytics Dashboard</h1>
          
          {/* Filters Section */}
          <div className="bg-gray-100 p-4 rounded-lg mb-6">
            <h2 className="text-lg font-semibold mb-2">Filters</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Vertical</label>
                <select
                  value={filters.vertical}
                  onChange={(e) => handleFilterChange('vertical', e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">All Verticals</option>
                  {verticals.map(v => (
                    <option key={v} value={v}>{v}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Group</label>
                <select
                  value={filters.group}
                  onChange={(e) => handleFilterChange('group', e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">All Groups</option>
                  {groups.map(g => (
                    <option key={g} value={g}>{g}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Subgroup</label>
                <select
                  value={filters.subgroup}
                  onChange={(e) => handleFilterChange('subgroup', e.target.value)}
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">All Subgroups</option>
                  {subgroups.map(sg => (
                    <option key={sg} value={sg}>{sg}</option>
                  ))}
                </select>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Search</label>
                <input
                  type="text"
                  value={filters.searchTerm}
                  onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
                  placeholder="Search by name or position..."
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
          </div>
          
          {/* Summary Stats */}
          <div className="bg-white p-4 rounded-lg shadow mb-6">
            <h2 className="text-lg font-semibold mb-4">Summary Statistics</h2>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="bg-blue-50 p-3 rounded-md">
                <h3 className="text-sm text-blue-600 font-medium">Interviews</h3>
                <p className="text-2xl font-bold">{filteredMetrics.length}</p>
              </div>
              <div className="bg-green-50 p-3 rounded-md">
                <h3 className="text-sm text-green-600 font-medium">Avg Current Use</h3>
                <p className="text-2xl font-bold">{averages.current_use} / 4</p>
              </div>
              <div className="bg-yellow-50 p-3 rounded-md">
                <h3 className="text-sm text-yellow-600 font-medium">Avg Attitude</h3>
                <p className="text-2xl font-bold">{averages.attitude} / 4</p>
              </div>
              <div className="bg-red-50 p-3 rounded-md">
                <h3 className="text-sm text-red-600 font-medium">Avg Impact Potential</h3>
                <p className="text-2xl font-bold">{averages.impact}%</p>
              </div>
            </div>
          </div>
          
          {/* Charts Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            {/* Vertical Comparison Chart */}
            <div className="bg-white p-4 rounded-lg shadow">
              <h2 className="text-lg font-semibold mb-4">Metrics by Vertical</h2>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={verticalData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0, 4]} yAxisId="left" />
                    <YAxis domain={[0, 100]} yAxisId="right" orientation="right" unit="%" />
                    <Tooltip 
                      formatter={(value, name) => {
                        return name === "impact" ? `${value}%` : value;
                      }}
                    />
                    <Legend />
                    <Bar dataKey="current_use" name="Current Use" fill="#0088FE" yAxisId="left" />
                    <Bar dataKey="attitude" name="Attitude" fill="#00C49F" yAxisId="left" />
                    <Bar dataKey="impact" name="Impact Potential" fill="#FF8042" yAxisId="right" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            
            {/* Distribution Charts */}
            <div className="bg-white p-4 rounded-lg shadow grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-md font-semibold mb-2">Current Use Distribution</h3>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={currentUseDistribution}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => {
                          // Only show label if percentage is greater than 0
                          return percent > 0 ? `${name}: ${(percent * 100).toFixed(0)}%` : '';
                        }}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {currentUseDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div>
                <h3 className="text-md font-semibold mb-2">Attitude Distribution</h3>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={attitudeDistribution}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) => {
                          // Only show label if percentage is greater than 0
                          return percent > 0 ? `${name}: ${(percent * 100).toFixed(0)}%` : '';
                        }}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {attitudeDistribution.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
          
          {/* Data Table Section */}
          <div className="bg-white p-4 rounded-lg shadow mb-6">
            <h2 className="text-lg font-semibold mb-4">Interview Details</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('name')}
                    >
                      Name{getSortDirectionIndicator('name')}
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('position')}
                    >
                      Position{getSortDirectionIndicator('position')}
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('vertical')}
                    >
                      Vertical{getSortDirectionIndicator('vertical')}
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('current_use')}
                    >
                      Current Use{getSortDirectionIndicator('current_use')}
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('attitude')}
                    >
                      Attitude{getSortDirectionIndicator('attitude')}
                    </th>
                    <th 
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                      onClick={() => requestSort('impact')}
                    >
                      Impact{getSortDirectionIndicator('impact')}
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {getSortedItems(filteredMetrics).map((metric) => (
                    <tr key={metric.name} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{metric.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.position}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.vertical}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.current_use}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.attitude}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{metric.impact}%</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => handlePersonSelect(metric)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard; 